import React, { Props } from "react"
import { Collapse, Input, Form, Icon, Button, Spin, InputNumber, Modal } from 'antd'
import { Helmet } from "react-helmet"
import SiteLayout from "../components/SiteLayout";
import axios from 'axios';



const Panel = Collapse.Panel;

enum Page {
    Confirm = 0,
    ThankYou = 1,
    Error = 2,
    Loading = 3,
    NoThankYou = 4
}

// @ts-ignore
import styles from './reserver.module.scss';
import { StaticQuery, graphql } from "gatsby";
import vippsButton from '../assets/images/betalMedVipps.svg';
import HowItWorks from "../components/Sections/HowItWorks";
import NerdMode from "../components/Sections/NerdMode";
import Pitch from "../components/Sections/Pitch";
import YouGet from "../components/Sections/YouGet";

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const API_URL = process.env.GATSBY_APP_API_URL;
const API_TOKEN = process.env.GATSBY_APP_API_TOKEN;
 
class BekreftForm extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            confirming: false,
            rejecting: false,
            buttonsActive: false,
            fieldsActive: true,
            page: Page.NoThankYou,
            submitSpinnerHidden: true,
        };

        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.lockForm = this.lockForm.bind(this);
        this.unlockForm = this.unlockForm.bind(this);
        this.handleEmptyValues = this.handleEmptyValues.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    componentDidMount() {
        //this.getConfirmation();

        /* const urlParams = new URLSearchParams(window.location.search);
        const orderId = urlParams.get('orderId');
        console.log("myParam: " + orderId);
        if (orderId !== null) {
            this.getOrderStatus(orderId);
            console.log("compDidMount orderId: " + orderId);
        }
        else {
           this.setState({ page: Page.Confirm }); 
        }  */
    }

   

    onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
        let fieldName = e.target.name;
        let value = e.target.value;

        const nextState = {
            ...this.state.confirmationRequest,
            [fieldName]: value,
        };
        this.setState({ confirmationRequest: nextState });

        this.handleEmptyValues(value);
    }

    getOrderStatus(orderId: string): string {
        var config = {
            headers: {'Authorization': "bearer " + API_TOKEN}
        };

        axios.get(API_URL + "/api/reservebox/orderStatus/" + orderId, config).then(res => {
            if (res.status >= 200 && res.status < 300) {
                console.log("getorderstatus status: " + res.data);
                this.renderStatusPage(res.data);
            }
        }).catch(err => {
            this.setState({ page: Page.Error });
            return null;
        })
        return null;
    }

    renderStatusPage(status) {
        console.log("renderStatusPage status: " + status);
        switch (status) {
            case "CAPTURE":
                this.setState({ page: Page.ThankYou });
                break;
            case "CANCEL" || "VOID":
                this.setState({ page: Page.NoThankYou});
                break;
            case "INITIATE" || "RESERVE":
                this.setState({ page: Page.Loading});
                const urlParams = new URLSearchParams(window.location.search);
                const orderId = urlParams.get('orderId');
                if (orderId !== null) {
                    setTimeout(this.getOrderStatus(orderId), 500000)
                } 
                else {
                    this.setState({ page: Page.Error });
                }
                break;
            default:
                this.setState({ page: Page.Confirm });
        }
    }

    

    handleEmptyValues(value: string) {
       
        setTimeout(function () {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                        this.setState({ buttonsActive: true });
                    
                } 
                if(this.state.confirmationRequest.samtykke.checked ){
                    this.setState({ buttonsActive: true });
                }
            });
        }.bind(this), 200);
    }

    handleSubmit = (e) => {
        console.log(this.state.confirmationRequest);
        this.setState({ submitSpinnerHidden: false });
        let request = this.state.confirmationRequest;
        var config = {
            headers: {'Authorization': "bearer " + API_TOKEN}
        };

        if (!this.state.buttonsActive) {
            this.setState({ submitSpinnerHidden: true });
            return null;
        }
        e.preventDefault();
        this.lockForm(true);
        //Add API here
        axios.post(API_URL + "/api/reservebox/initiatePayment", request, config).then(res => {
            if (res.status >= 200 && res.status < 300) {
                window.location.replace(res.data.url);
                this.setState({ submitSpinnerHidden: true });
            }
        }).catch(err => {
            this.setState({ page: Page.Error, submitSpinnerHidden: true });
        })
    }

    lockForm(confirming: boolean) {
        this.setState({ buttonsActive: true, fieldsActive: false, confirming: confirming, rejecting: !confirming });
    }

    unlockForm() {
        this.setState({ buttonsActive: true, fieldActive: true, confirming: false, rejecting: false });
    }

    showModal() {
        Modal.info({
            title: "Reservasjons-vilkår",
            content: (
                <div>
                    <h4>1. Reservasjon av Oss-Brikken </h4>
                    <p> Grunnet stor etterspørsel for Oss-Brikken får du anledning til å reservere en plass i køen for nye Oss-brikker som er bestilt. For å sikre alle kunder lik mulighet til å reservere, er det kun du som sluttkunde som kan reservere en plass i bestillingskøen gjennom vårt system. Du har derfor ikke anledning til å gjøre en reservasjon på vegne av andre utenfor din husstand. Det er kun tillat å foreta en (1) reservasjon per person. Du må være over 18 år og eier av et målepunkt for å foreta en reservasjon. Reservasjonen gjelder kun for bestilling av Oss-Brikken i det norske markedet for personlig bruk i Norge. </p>
                    <h4>2. Levering</h4>
                    <p> Forventet leveringstid er mars-april 2020, men du vil bli holdt oppdatert om leveringstid. Vi tar forbehold om at opplysningene om levering kan endres. Den faktiske leveringstiden på Oss-Brikken kan påvirkes av flere faktorer, for eksempel, men ikke begrenset til, tilgang på komponenter og materiale, produksjonstid og distribusjonstid. </p>
                    <h4>3. Beløp</h4>
                    <p> For at din reservasjon skal være gyldig må beløpet på kr 200,- være mottatt hos oss. Når betalingen av beløpet er gjennomført mottar du en skriftlig bekreftelse fra oss på din reservasjon via e-post med ditt ordrenummer. Ordrenummer er personlig og kan benyttes som identifikasjon ved henvendelser med oss. Vi kan kontaktes via hei@oss.no. Ved kansellering av reservasjonen vil du automatisk miste din plass i køen og det innbetalte beløpet vil tilbakebetales til din konto normalt innen fem virkedager. </p>
                    <h4>4. Pris</h4>
                    <p> Forespeilet pris for Oss-Brikken vil være kr 990,- inklusive mva. og med en månedskostnad på kr 39,- inklusive mva. Vi tar forbehold om at prisen kan endre seg frem til bestillingstidspunktet. </p>
                    <h4>5. Kjøpekontrakt</h4>
                    <p> Reservasjonen gir deg en prioritert plass i køen for bestilling av Oss-Brikker. Den utgjør ikke et kjøp, en endeling bestilling med leveransedato og garantert leveringsprioritet. Du vil bli kontakt når det åpnes for bestilling av Oss-Brikker, hvor du vil få tilbud om å inngå en kjøpekontrakt og foreta en endelig bestilling av Oss-Brikken. Når det åpnes for bestilling vil du ha en tidsbegrenset periode til å legge inn din bestilling. Når kjøpekontrakt er inngått vil du få det innbetalte beløpet trukket fra kjøpesum. </p>
                </div>
            ),
            onOk() {},
            width: 650
        });
    }


    renderConfirmPage() {
        const { getFieldDecorator } = this.props.form;

        return (
            <StaticQuery
                query={graphql`
                {
                    boxImg: file(relativePath: {eq: "betalMedVipps.png"}) {
                        childImageSharp{
                        fluid(maxWidth: 3000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }
                }
                `
                }
                render={data => (
                    <div>
                        <h1>Reserver din Oss-brikke</h1>
                        <div>
                            <p className={styles.introtext}>
                                Forventet levering er mars-april 2020
                                <br/>200kr i depositum
                            </p>
                            <Form hideRequiredMark={true} onSubmit={this.handleSubmit} className={styles.confirmform} layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <Form.Item className={styles.compactfield} label="Fornavn">
                                    {getFieldDecorator('firstname', {
                                        rules: [{ required: true, message: 'Du må fylle inn fornavn' }],
                                        
                                    })(
                                        <Input autoComplete="off" disabled={!this.state.fieldsActive} name="firstname" size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Fornavn" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    label="Etternavn"
                                >
                                    {getFieldDecorator('lastname', {
                                        rules: [{ required: true, message: 'Du må fylle inn etternavn' }],
                                        
                                    })(
                                        <Input disabled={!this.state.fieldsActive} name="lastname" size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Etternavn" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    label="Telefonnummer"
                                >
                                    {getFieldDecorator('phoneNumber', {
                                        rules: [{ required: true, message: 'Du må fylle inn telefonnummer' }],
                                        
                                    })(
                                        <Input disabled={!this.state.fieldsActive} name="phoneNumber" size="large" prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Telefonnummer" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="E-post"
                                >
                                    {getFieldDecorator('email', {
                                        rules: [{ required: true, message: 'Du må fylle inn epost!' }],
                                        
                                    })(
                                        <Input disabled={!this.state.fieldsActive} name="email" size="large" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="E-post" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>
                                <div className={styles.introsubtext}>Jeg har lest og akseptert vilkår og betingelser, og jeg samtykker i at Oss kan kontakte meg </div>
                                <a href="#" onClick={this.showModal} className={styles.reservationLink}> Reservasjonsvilkår </a>
                                <Form.Item
                                    label="Samtykke"
                                >
                                    {getFieldDecorator('samtykke', {
                                        rules: [{ required: true, message: 'Du må samtykke for å reservere brikke!' }],
                                        
                                    })(
                                        <Input disabled={!this.state.fieldsActive} type="checkbox" name="samtykke" size="large" defaultValue="false" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />}  onChange={this.onChangeInput}  />
                                    )}
                                </Form.Item>
                                <br />
                                <div onClick={this.handleSubmit} className={styles.vippsButtonContainer}><img alt="Betal med Vipps" src={vippsButton} height="auto" width="350px" className={styles.vippsButton}/></div> <div hidden={this.state.submitSpinnerHidden}><Icon type="loading" style={{ fontSize: 24 }}  /></div>
                            </Form>
                        </div>
                        <div className={styles.introsubtext}>
                            <YouGet className={styles.yougetModule}/>
                        </div>
                    </div>
                )}
            />
        )
    }

    renderLoadingPage() {
        return (
            <div>
                <h1>Laster inn...</h1>
                <div className={styles.introtext}>Vent litt mens vi henter frem siden.<br /><br /><Spin indicator={antIcon} /></div>
            </div>
        )
    }


    renderThankYouPage() {
        //window.scrollTo(0, 0);
        return (
            <div>
                <div className={styles.header}>Tusen takk!</div>
                    <div className={styles.introtext}>
                        Gratulerer, du har nå reservert en Oss-brikke. Utrolig kult at du ønsker å bli en del av Oss. Vi vil holde deg oppdatert på reisen til brikken din, helt til den er levert hjem til deg.
                
                        <br/>
                        <br/> Du hører fra oss. 
                    </div>
            </div>
        )
    }

    renderNoThankYouPage() {
        //window.scrollTo(0, 0);
        return (
            <div>
                {/* <h1>Det er notert!</h1>
                <div className={styles.introtext}>Din reservasjon er nå slettet og du vil <u>ikke</u> få en Oss-brikke i posten. Ha en fortsatt topp&nbsp;dag!</div> */}

                <h1>Vi kommer straks ut med en ny bestillingsløsning</h1>
                <div className={styles.introtext}>Sjekk igjen senere</div>
            </div>
        )
    }

    renderErrorPage() {
        //window.scrollTo(0, 0); - gatsby build error.
        return (
            <div>
                <h1>Problemer med lagring av reservasjon</h1>
                <div className={styles.introtext}>Prøv igjen eller ta kontakt med oss dersom det fortsatt feiler. Kontakt Oss på <a href="mailto:hei@oss.no">hei@oss.no</a> eller tekstboblen nede i høyre hjørne.</div>
            </div>
        )
    }

    renderPage() {
        
        if (this.state.page == Page.Confirm) {
            return this.renderConfirmPage();
        }
        if (this.state.page == Page.ThankYou) {
            return this.renderThankYouPage();
        }
        if (this.state.page == Page.Error) {
            return this.renderErrorPage();
        }
        if (this.state.page == Page.Loading) {
            return this.renderLoadingPage();
        }
        if (this.state.page == Page.NoThankYou) {
            return this.renderNoThankYouPage();
        }
    }

    render() {
        return (
            <SiteLayout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Oss | Reserver</title>
                    <script>{`
                    {
            window.intercomSettings = {
                app_id: "wpsqtjpa"
            };
            }`}
                    </script>
                    <script>{`
                    {
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/wpsqtjpa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
            }`}
                    </script>
                </Helmet>
                <div className={styles.container}>
                    {this.renderPage()}
                </div>
            </SiteLayout >
        );
    }
}

export default class Bekreft extends React.Component<any, any> {
    render() {
        const BekreftFormComponent = Form.create()(BekreftForm);
        return (<BekreftFormComponent />);
    }
}