import React from 'react'
import { Row, Col, Button } from 'antd'
import Img from "gatsby-image";

// @ts-ignore
import styles from './youget.module.scss';

import { StaticQuery, graphql, Link } from 'gatsby';



interface YouGetState {
    //
}

export default class YouGet extends React.Component<any, YouGetState> {

    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    render() {



        return (
            <StaticQuery
                query={graphql`
                {
                    pitch: file(relativePath: {eq: "kalkulator_cropped.png"}) {
                        childImageSharp{
                        fluid(maxWidth: 680) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                    }
                }
            `
                }
                render={data => (
                    <div>
                        <div className={styles.header}>Med Oss får du...</div>
                        <div className={styles.introtext}>
                            <p>Pris 39kr/mnd + 990kr for Oss-brikken (engangssum)*</p>
                            
                            <p>Gira på å få et litt mer forståelig forhold til strøm? Dette kan vi hjelpe deg med, 
                            hvis du sikrer deg en av Oss-brikkene som er på vei.</p>
                            <p>* Forbehold om endring</p>
                        </div>
                        <Row className={styles.container}>
                                {/* <Col xs={24} sm={24} lg={7}>
                                    <div className={styles.imagecontainer}>
                                        <Img title="Oss-Testbruker" fluid={data.pitch.childImageSharp.fluid} className={styles.image} alt="Oss-Testbruker illustrasjon" />
                                    </div>
                                </Col> */}
                                <Col xs={24} sm={24} lg={24}>
                                    <Row type="flex" align="top" gutter={24}>
                                        <Col xs={24} sm={12} lg={10}>
                                            <ul>
                                                <li className={styles.bulletitem}>Et trygt og forutsigbart forhold til strøm</li>
                                                <li className={styles.bulletitem}>Innsikt og oversikt i eget strømforbruk</li>
                                                <li className={styles.bulletitem}>Enkle sammenligninger</li>
                                                <li className={styles.bulletitem}>Relevante varsler</li>
                                                <li className={styles.bulletitem}>Eierskap til din egen data</li>
                                            </ul>
                                        </Col>
                                        <Col xs={24} sm={12} lg={14}>
                                            <div className={styles.imagecontainer}>
                                                <Img title="Oss-kalkulator" fluid={data.pitch.childImageSharp.fluid} className={styles.image} alt="Oss-Kalkulator illustrasjon" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24} lg={24}>
                                            <div className={styles.subheader}>Se strømforbruket ditt akkurat nå</div>
                                            <div className={styles.text}>
                                                Hvor mye strøm bruker du akkurat nå? For folk flest er det helt gresk. Dette gir Oss deg muligheten til å se nå.  
                                                Kanskje klarer du ved hjelp av appen å finne strømtyvene i hjemmet ditt?
                                            </div>
                                            <div className={styles.subheader}>Se ditt historiske forbruk</div>
                                            <div className={styles.text}>
                                                Spol tilbake i tid og se ditt historiske strømforbruk. Denne tjenesten gir deg muligheten til å sammenligne forbruket ditt på timer, dager, uker, måneder og år. 
                                            </div>
                                            <div className={styles.subheader}> Sammenlign forbruket ditt med tilsvarende hjem </div>
                                            <div className={styles.text}>
                                                Få et forhold til hvordan strømforbruket ditt er sammenlignet med lignende hjem. Er du en bedre strømsparer enn naboen? 
                                            </div>
                                            <div className={styles.subheader}>Prediksjon av forbruk</div>
                                            <div className={styles.text}>
                                                Med våre smarte algoritmer kan vi fortelle deg hvor mye strøm du mest sannsynlig kommer til å bruke i morgen.
                                            </div>
                                            <div className={styles.subheader}>Smarte varslinger</div>
                                            <div className={styles.text}>
                                                Er strømforbruket ditt høyere enn normalt eller har strømprisen gått betydelig opp i Norge, så gir vi deg beskjed (bare hvis du selv ønsker så klart).
                                            </div>
                                            <div className={styles.subheader}>Legg inn din strømavtale</div>
                                            <div className={styles.text}>
                                                Hvis du legger inn strømavtalen din kan du følge med på hvor høy strømregningen blir, slik at du slipper en vond overraskelse på slutten av måneden.
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                        </Row>
                </div>
                )}
            />
        )
    }
}